<template>
  <div class="mode" :style="isMobile ? 'right: 0;bottom: 72px;' : (factor === 'precp'?'background-color: rgba(0, 0, 0, 0)':'')">
    <div
      v-for="item in menu"
      :key="item"
      class="item"
      :style="isMobile ? 'padding: 0px 10px;' : ''"
      :class="t1Mode === item ? 'active' : ''"
      @click="setModeType(item)"
    >
      {{ item }}
      <div class="text" v-show="t1Mode === item">{{ "12km" }}</div>
    </div>
    <div class="text precp-text" v-if="factor === 'precp'">{{ CY }}<a style="color: rgb(2, 255, 158);text-decoration: underline " href="https://caiyunapp.com/"  target="_blank">彩云天气</a></div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import {mapForCn } from "@/config/MapConfigT4";
import {TIANJI2_MERGE, TIANJI2_DA, TIANJI2_ND, TIANJI1_DA, TIANJI1_ND, CY} from "@/config";
import { drag } from "@/utils/drag";

export default {
  data() {
    return {
      types: [TIANJI2_MERGE, TIANJI2_ND, TIANJI2_DA, TIANJI1_ND, TIANJI1_DA],
      isMove: false,
      CY:CY
    };
  },
  computed: {
    ...mapState(["factor", "t1Mode"]),
    ...mapState("map", ["t2Mode"]),
    isMobile() {
      return this.$store.state.isMobile;
    },
    menu() {
      if (["max_reflectivity", "base_reflectivity", "rh2m"].includes(this.factor)) {
        return [TIANJI2_MERGE, TIANJI2_ND, TIANJI2_DA];
      }else if(['ri_min'].includes(this.factor)) {
        return [TIANJI2_ND, TIANJI2_DA, TIANJI1_ND, TIANJI1_DA];
      }else if(['precp'].includes(this.factor)){
        return [];
      }
      return this.types;
    },
  },
  mounted() {
    drag(this.$el);
    this.$el.addEventListener("mousedown", (event) => {
      this.handleMouseDown();
    });
    this.$el.addEventListener("mouseup", (event) => {
      this.handleMouseUp();
      setTimeout(() => {
        this.isMove = false;
      }, 500);
    });
  },
  methods: {
    ...mapMutations(["setMode"]),
    ...mapMutations("map", ["setFactor"]),
    // 组件中的方法
    handleMouseDown() {
      this.$el.addEventListener("mousemove", this.handleMouseMove);
    },
    handleMouseMove(event) {
      // 监听鼠标移动事件时的逻辑
      this.isMove = true;
    },
    handleMouseUp() {
      this.$el.removeEventListener("mousemove", this.handleMouseMove);
    },
    setModeType(item) {
      localStorage.setItem('current-mode',item)
      if (item === CY) return;
      if(this.isMove) return;
      const from_mode = sessionStorage.getItem("tmode") ? this.t1Mode : this.t2Mode
      if (item !== TIANJI1_DA && item !== TIANJI1_ND) {
        console.log('eeeeeeeee')
        sessionStorage.setItem("tmode", "t2");
        if (item === TIANJI2_MERGE && this.factor === "t2mz") {
          this.$sensors.track("currentMode", {
            info: mapForCn["tmp2m"],
            mode: item,
            space_type: this.$route.name === "earth" ? '三维' : '二维',
            page_type: "天机2",
          });
          this.$sensors.track("Jump_volume", {
            from_factor: mapForCn["tmp2m"],
            from_mode: from_mode,
            from_space_type: this.$route.name === "earth" ? '三维' : '二维',
            to_mode: item,
            to_factor: mapForCn["tmp2m"],
            to_space_type: this.$route.name === "earth" ? '三维' : '二维',
          });
          this.setFactor("tmp2m");
        } else {
          this.$sensors.track("currentMode", {
            info: mapForCn[this.factor],
            mode: item,
            space_type: this.$route.name === "earth" ? '三维' : '二维',
            page_type: "天机2",
          });
          this.$sensors.track("Jump_volume", {
            from_factor: mapForCn[this.factor],
            from_mode: from_mode,
            from_space_type: this.$route.name === "earth" ? '三维' : '二维',
            to_mode: item,
            to_factor: mapForCn[this.factor],
            to_space_type: this.$route.name === "earth" ? '三维' : '二维',
          });
          this.setFactor(this.factor);
        }
        this.$store.commit("map/setMode", item);
        this.$router.push({ name: "weather_hd" });
        // this.setFactor
      } else {
        this.setMode(item);
        this.$sensors.track("currentMode", {
          info: mapForCn[this.factor],
          mode: this.t1Mode,
          space_type: this.$route.name === "earth" ? '三维' : '二维',
          page_type: "天机1",
        });
        this.$sensors.track("Jump_volume", {
          from_factor: mapForCn[this.factor],
          from_mode: from_mode,
          from_space_type: this.$route.name === "earth" ? '三维' : '二维',
          to_mode: this.t1Mode,
          to_factor: mapForCn[this.factor],
          to_space_type: this.$route.name === "earth" ? '三维' : '二维',
        });
        // if (item !== TIANJI1_DA && item !== TIANJI1_ND){
        //   sessionStorage.setItem("tmode", "短临");
        //   this.$store.commit("setFactor", 'precp');
        // }else {
        //   sessionStorage.setItem("tmode", "t1");
        // }

      }
    },
  },
};
</script>

<style scoped lang="scss">
.mode {
  position: fixed;
  z-index: 998;
  right: 70px;
  bottom: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 6px;
  .text {

    margin-left: 5px;
  }

  .precp-text{
    font-size: 22px;
    background-color: rgba(255, 255, 255, 0) !important;
    border: none !important;
    line-height: 42px;
    font-weight: 600;
    color: #FFF;
  }

  .item {
    padding: 0 16px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    border-radius: 6px;
    text-shadow: 0px 0px 4px black;
  }

  .active {
    background-color: $theme-color;
    display: flex;
    padding: 0 11px;
  }
}
</style>
