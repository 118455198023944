import Vue from 'vue';
import Vuex from 'vuex';
import { getImage } from '@/api/image';
import earth from './earth/index';
import map from './map/index';
import satellite from './satellite/index';
import typhoon from './typhoon/index';
import { TIANJI1_DA } from '@/config';
import {filterTimeList} from "@/config/Utils";

Vue.use(Vuex);

function isMobileFn() {
  const mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return !!mobile;
}

const mobile = isMobileFn();

export default new Vuex.Store({
  state: {
    userType: sessionStorage.getItem('userType') || '外部',
    isLogin: false,
    myToken: '',
    userInfo: '',
    historyPath: '',
    isShowInfo: false,
    showMetricState: false,
    t1Mode: localStorage.getItem('current-mode') || TIANJI1_DA,
    timeTypeMap: {},
    factor: sessionStorage.getItem("factor") || 'pratesfc',
    currentFactorData: [],
    currentSlpData: [],
    currentWindData: [],
    currentData: {},
    status: 'pause',
    factorFirstData: [],
    baseTime: [],
    timeType:{
      type: 1,
      timeLength: 24,
    },
    currentBaseTime: {},
    // windy: mobile ? 'none' : 'normal',
    windy: 'none',
    isMobile: mobile,
    layerType: 'shadow',
    t1LayerType: 'graph',
    mapCenter: '',
    precipIndex: 0,
    // 城市图层是否展示
    city: 'normal',
    currentTimestamp: '',
  },
  getters: {
    factorDataTimeMap(state) {
      // if (state.factor !== "precip") {
      //   return state.currentFactorData.reduce((target, item) => {
      //     target[+new Date(item.timestamp)] = item;
      //     return target;
      //   }, {});
      // }else {
      //   return state.currentFactorData.forecast.reduce((target, item) => {
      //     target[+new Date(item.forecastTimeString)] = item;
      //     return target;
      //   }, {});
      // }

      return state.currentFactorData.reduce((target, item) => {
        target[+new Date(item.timestamp)] = item;
        return target;
      }, {});

    },

    firstDataMap(state) {
      return state.factorFirstData.reduce((target, item) => {
        target[item.factorCode] = item;
        item.index = 0;
        return target;
      }, {});
    },
    currentSlp(state) {
      const index = state.currentData.index;
      return state.currentSlpData[index] || null;
    },

    currentWind(state) {
      const index = state.currentData.index;
      return state.currentWindData[index] || null;
    },

    // 当前选择时间
    currentTime(state, getters) {
      return getters.current.forecastTime;
    },
  },
  mutations: {
    setLogin(state, val) {
      state.isLogin = val;
    },
    //todo 时间轴
    setTimeType(state, val) {
      if (!state.timeTypeMap[state.timeType.type] || !this.getters['factorDataTimeMap'][+new Date(state.currentData.timestamp)]) {
        // localStorage.setItem('timeType', JSON.stringify(val));
        state.timeType = val;
        return;
      }
      //原有的时间列表和选中时间
      const oldList = state.timeTypeMap[state.timeType.type];
      const oldTime = state.currentData.timestamp;

      //从现有的时间列表找最相近的时间
      const NewList = state.timeTypeMap[val.type];

      const targetDate = new Date(oldTime); // 转换目标时间为 Date 对象
      let minDifference = Infinity; // 初始化最小差值为正无穷
      let closestIndex = -1; // 初始化最相近时间的索引为 -1

      NewList.forEach((timeObj, index) => {
        const time = new Date(timeObj.timestamp);
        const difference = Math.abs(time - targetDate); // 计算当前时间与目标时间的差值

        if (difference === 0) {
          closestIndex = timeObj; // 如果差值为 0，说明找到了相同的时间，更新索引并跳出循环
          minDifference = 0; // 更新最小差值


          return;
        }

        if (difference < minDifference) {
          minDifference = difference; // 更新最小差值
          closestIndex = timeObj; // 更新最相近时间的索引
        }
      });
      state.currentData = closestIndex;
      console.log('切换的日期', new Date(closestIndex.timestamp), closestIndex);

      // console.log('state.index', state.index);
      // localStorage.setItem('timeType', JSON.stringify(val));
      state.timeType = val;
      state.currentFactorData = NewList;
    },
    setShowInfo(state, val) {
      state.isShowInfo = val;
    },
    setHistory(state, val) {
      state.historyPath = val;
    },
    setShowMetric(state, val) {
      state.showMetricState = val;
    },
    setToken(state, val) {
      localStorage.setItem('myToken', val);
      sessionStorage.setItem('myToken', val);
      state.myToken = val;
    },
    setUserInfo(state, val) {
      state.userInfo = val;
    },
    setMode(state, val) {
      state.t1Mode = val;
    },
    setFactor(state, value) {
      state.factor = value;
    },
    setCenter(state, val) {
      state.mapCenter = val;
    },
    setCurrentFactorData(state, { factor, slp, wind }) {

      const filteredTimeArray3 = filterTimeList(factor, 3);
      const filteredTimeArray6 = filterTimeList(factor, 6);
      const filteredTimeArray24 = filterTimeList(factor, 24);
      state.timeTypeMap = {
        1: factor,
        3: filteredTimeArray3,
        6: filteredTimeArray6,
        // 12: filteredTimeArray12,
        24: filteredTimeArray24,
      }
      console.log('时间列表', state.timeTypeMap);
      state.currentFactorData = state.timeTypeMap[state.timeType.type];
      state.currentSlpData = slp;
      state.currentWindData = wind;
    },
    setCurrentData(state, value) {
      state.currentData = value;
      sessionStorage.setItem('nowTime', state.currentData.timestamp);
    },

    setStatus(state, value) {
      state.status = value;
    },

    setFactorFirstData(state, value) {
      value.forEach((item) => {
        item.index = 0;
        item.getImage = getImage(item.thumbnailPath).then((res) => {
          item.image = res;
          return res;
        });
      });
      state.factorFirstData = value;
    },

    // 设置当前的地形图
    setT1LayerType(state, value) {
      state.layerType = value;
      state.t1LayerType = value;
    },

    // 设置城市图层是否展示值
    setT1City(state, value) {
      state.city = value;
    },

    setBaseTime(state, value) {
      state.baseTime = value.map((item) => {
        item.baseTimeModel = `${item.baseTime} ${item.mode === 'early' ? 'DA' : (item.mode === 'CY'?'':'ND')}`;
        return item;
      });
    },

    setCurrentBaseTime(state, value) {
      state.currentBaseTime = value;
      sessionStorage.setItem('baseTime', state.currentBaseTime.baseTime);
    },

    setT1Windy(state, value) {
      state.windy = value;
    },

    setCurrentTimestamp(state, value) {
      state.currentTimestamp = value;
    },
  },
  actions: {
  },
  modules: {
    earth,
    map,
    satellite,
    typhoon,
  },
});
